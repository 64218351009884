<template>
  <q-form ref="editForm">
    <!-- 점검설비 상세 -->
    <c-card title="LBL0002116" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 완료 -->
          <c-btn 
            v-show="editInfo&&preStartupCheck.sopPrestartupCheckId&&!disabled" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="preStartupEquipment"
            mappingType="PUT"
            label="LBLCOMPLETE" 
            icon="check"
            @beforeAction="completePreStartupEquipment"
            @btnCallback="completeCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
          <!-- 설비유형 -->
          <c-text
            :editable="editInfo"
            :disabled="true"
            label="LBL0000633"
            name="equipmentTypeName"
            v-model="preStartupEquipment.equipmentTypeName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <!-- 점검유형 -->
          <c-multi-select
            :editable="editInfo"
            :disabled="disabled"
            :isObject="true"
            :disabledItems="disabledItems"
            codeGroupCd="PSR_CHECK_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            valueText="psrCheckTypeName"
            valueKey="psrCheckTypeCd"
            label="LBL0002122"
            name="checkTypes"
            v-model="preStartupEquipment.checkTypes"
            @datachange="changeCheckType">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
          <!-- 설비점검책임자 -->
          <c-field 
            :editable="editInfo"
            :disabled="disabled"
            type="user" 
            label="LBL0002117" 
            name="checkUserId" 
            v-model="preStartupEquipment.checkUserId" />
        </div>
        <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
          <!-- 점검예정일 -->
          <c-datepicker
            :editable="editInfo"
            :disabled="disabled"
            label="LBL0002118"
            name="checkScheduleDate"
            v-model="preStartupEquipment.checkScheduleDate"
          />
        </div>
        <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
          <!-- 점검일 -->
          <c-datepicker
            :editable="editInfo"
            :disabled="disabled"
            label="LBL0002123"
            name="checkDate"
            v-model="preStartupEquipment.checkDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <!-- 점검결과종합 -->
          <c-textarea
            :editable="editInfo"
            :disabled="disabled"
            label="LBL0002120"
            name="checkResultTotal"
            v-model="preStartupEquipment.checkResultTotal">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <!-- 점검결과 목록 -->
    <c-table
      ref="table"
      title="LBL0002121"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="preStartupEquipment.checkItems"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editInfo&&!disabled"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editInfo&&!disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="sopPrestartupEquipmentHasCheckItemId"
            ibmTaskTypeCd="ITT0000030"
            ibmTaskUnderTypeCd="ITTU000050"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'pre-startup-facility',
  props: {
    param: {
      type: Object,
      default: function() {
        return {
          sopPrestartupCheckId: '',
          isSearch: false,
        }
      }
    },
    preStartupCheck: {
      type: Object,
      default: function() {
        return {
          plantCd: null,
          sopPrestartupCheckId: '',
          equipments: [],
        }
      }
    },
    equipmentCd: {
      type: String,
      default: function() {
        return ''
      }
    },
    research: {
      type: Object,
      default: () => ({
        impr: '',
        item: '',
      }),
    },
  },
  data() {
    return {
      preStartupEquipment: {
        sopPrestartupCheckId: '',  // 가동전점검 일련 번호
        equipmentCd: '',  // 설비코드
        equipmentName: '',  // 설비명
        equipmentTypeCd: '',  // 설비 분류(상하위구조)
        equipmentTypeName: '',  // 설비 분류명(상하위구조)
        checkUserId: '',  // 점검 책임자
        checkScheduleDate: '',  // 점검 예정일
        checkDate: '',  // 점검일
        checkResultTotal: '',  // 점검 결과 종합
        checkCompleteFlag: 'N',  // 점검 완료 여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checkTypes: [], // 점검유형
        checkItems: [], // 점검항목
        deleteCheckItems: [],
      },
      grid: {
        merge: [
          { index: 0, colName: 'psrCheckTypeName' },
          { index: 1, colName: 'level1' },
          { index: 2, colName: 'level2' },
        ],
        columns: [],
        data: [],
        height: '450px'
      },
      requestImprRow: null,
      editable: true,
      listUrl: '',
      completeUrl: '',
      isComplete: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    editInfo() {
      return this.editable && !this.param.isSearch
    },
    disabled() {
      // 점검완료인 경우 비활성화
      return this.preStartupEquipment.checkCompleteFlag === 'Y' || this.preStartupCheck.psrCheckStatusCd === 'PCSC000005';
    },
    disabledItems() {
      let result = [];
      let disabledSteps = ['IS00000005', 'IS00000010', 'IS00000015', 'IS00000020']
      if (this.preStartupEquipment 
        && this.preStartupEquipment.checkItems 
        && this.preStartupEquipment.checkItems.length > 0) {
        this.$_.forEach(this.preStartupEquipment.checkItems, checkItem => {
          if (checkItem.improves && checkItem.improves.length > 0) {
            this.$_.forEach(checkItem.improves, improve => {
              if (this.$_.indexOf(disabledSteps, improve.ibmStepCd) > -1) {
                result.push(checkItem.psrCheckTypeCd);
                return false;
              }
            })
          }
        })
      }
      return result;
    },
    checkTypes() {
      return this.$_.map(this.preStartupEquipment.checkTypes, 'psrCheckTypeCd')
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['level2'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
    'preStartupCheck': {
      handler: function () {
        this.preStartupEquipment = this.$_.find(this.preStartupCheck.equipments, { equipmentCd: this.equipmentCd });
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      },
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.psr.check.checkTypeItemList.url
      this.completeUrl = transactionConfig.sop.psr.check.equipment.complete.url
      // code setting
      this.$comm.getComboItems('PSR_CHECK_PERIOD_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'psrCheckTypeName', 
            field: 'psrCheckTypeName',
            // 점검유형
            label: 'LBL0002122',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            // 점검항목
            label: 'LBL0002124',
            sortable: false,
            child: [
              {
                name: 'level1',
                field: 'level1',
                // 대분류
                label: 'LBL0002125',
                align: 'left',
                style: 'width:200px',
                sortable: false,
              },
              {
                name: 'level2',
                field: 'level2',
                // 중분류
                label: 'LBL0002126',
                align: 'left',
                style: 'width:400px',
                sortable: false,
              },
              {
                name: 'level3',
                field: 'level3',
                // 소분류
                label: 'LBL0002127',
                align: 'left',
                style: 'width:200px',
                sortable: false,
              },
            ]
          },
          {
            name: 'psrCheckPeriodCd',
            field: 'psrCheckPeriodCd',
            // 점검시기
            label: 'LBL0002128',
            align: 'center',
            style: 'width:90px',
            sortable: false,
            type: 'select',
            comboItems: _result,
            setHeader: true,
          },
          {
            name: 'judgmentResultFlag',
            field: 'judgmentResultFlag',
            // 적합여부
            label: 'LBL0002129',
            align: 'center',
            style: 'width:90px',
            sortable: false,
            type: 'select',
            comboItems: [
              { code: 'Y', codeName: '적합' },
              { code: 'N', codeName: '부적합' },
              { code: 'D', codeName: 'N/A' },
            ],
            setHeader: true,
          },
          {
            name: 'checkResult',
            field: 'checkResult',
            // 점검결과
            label: 'LBL0002130',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'text',
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선번호 / 진행상태
            label: 'LBL0002131',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
          // {
          //   name: 'remark',
          //   field: 'remark',
          //   label: 'LBLREMARK',
          //   align: 'left',
          //   style: 'width:150px',
          //   sortable: false,
          //   type: 'text',
          // },
        ];
      });
      // list setting
      this.preStartupEquipment = this.$_.find(this.preStartupCheck.equipments, { equipmentCd: this.equipmentCd });
    },
    changeCheckType(val) {
      if (!val || val.length === 0) {
        this.$_.forEach(this.preStartupEquipment.checkItems, deleteItem => {
          if (this.$_.findIndex(this.preStartupEquipment.deleteCheckItems, { sopPrestartupCheckItemId: deleteItem.sopPrestartupCheckItemId }) === -1
            && deleteItem.editFlag !== 'C') {
            this.preStartupEquipment.deleteCheckItems.push(deleteItem)
          }
        })
        this.preStartupEquipment.checkItems = [];
      } else {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.preStartupCheck.plantCd,
          checkTypes: this.$_.map(val, 'value')
        }
        this.$http.request((_result) => {
          if (_result.data && _result.data.length > 0) {
            if (this.preStartupEquipment.checkItems && this.preStartupEquipment.checkItems.length > 0) {
              this.$_.forEach(_result.data, item => {
                if (this.$_.findIndex(this.preStartupEquipment.checkItems, { sopPrestartupCheckItemId: item.sopPrestartupCheckItemId }) === -1) {
                  item.editFlag = 'C'
                  this.preStartupEquipment.checkItems.push(item);
                }
              })

              let deleteItems = [];
              this.$_.forEach(this.preStartupEquipment.checkItems, item => {
                if (this.$_.findIndex(_result.data, { sopPrestartupCheckItemId: item.sopPrestartupCheckItemId }) === -1) {
                  deleteItems.push(item);
                }
              })
              if (deleteItems && deleteItems.length > 0) {
                if (!this.preStartupEquipment.deleteCheckItems) this.preStartupEquipment.deleteCheckItems = [];
                this.$_.forEach(deleteItems, deleteItem => {
                  this.preStartupEquipment.checkItems = this.$_.reject(this.preStartupEquipment.checkItems, { sopPrestartupCheckItemId: deleteItem.sopPrestartupCheckItemId })

                  if (this.$_.findIndex(this.preStartupEquipment.deleteCheckItems, { sopPrestartupCheckItemId: deleteItem.sopPrestartupCheckItemId }) === -1
                    && deleteItem.editFlag !== 'C') {
                    this.preStartupEquipment.deleteCheckItems.push(deleteItem)
                  }
                })
              }
            } else {
              this.$_.forEach(_result.data, item => {
                item.editFlag = 'C'
              })
              this.preStartupEquipment.checkItems = _result.data;
            }
          } else {
            this.preStartupEquipment.checkItems = [];
          }
        },);
      }
    },
    completePreStartupEquipment() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.preStartupEquipment.checkItems.length > 0) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGCOMPLETE', // 완료하시겠습니까?,
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.preStartupEquipment.chgUserId = this.$store.getters.user.userId
                this.isComplete = !this.isComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: 'MSG0000606', // 점검결과 항목이 없습니다.'
              type: 'warning', // success / info / warning / error
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      this.preStartupEquipment.checkCompleteFlag = 'Y'
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    // 개선창 닫기 후
    imprChange() {
      this.$set(this.research, 'impr', uid())
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>